import { get } from 'lodash';

const useProductLogo = (productData = {}) => {
  const photo = get(productData, 'photo');
  const type = get(productData, 'type');

  const makeResponse = (src, fallback) => ({
    fallback,
    src,
    type,
  });

  return photo
    ? makeResponse(photo, false)
    : {
        src: '/NoImage.png',
      };
};

export default useProductLogo;
