import React from 'react';
import Image from 'gatsby-image';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import useProductLogo from '../useProductLogo';

const useStyle = makeStyles((theme) => ({
  thumbnail: ({
    fallback,
    backgroundColor = 'transparent',
  }) => ({
    backgroundColor,
    height: '100%',
    maxHeight: fallback ? '70%' : '100%',
    width: '100%',
  }),
  thumbnailContainer: ({
    backgroundColor = theme.palette.background.muted,
    size,
  }) => ({
    backgroundColor,
    height: size === 'small' ? 120 : 450,
    margin: '0 auto',
    padding: theme.spacing(2),
    position: 'relative',
    textAlign: 'center',
  }),
  thumbnailCaption: {
    fontSize: '0.833rem',
    lineHeight: 1.2,
  },
}));

const ProductPhoto = (props) => {
  // eslint-disable-next-line
  const { defer = true } = props;
  const { src, fallback } = useProductLogo(props);
  const { thumbnail, thumbnailContainer } = useStyle({
    ...props,
    fallback,
  });

  return (
    <Box className={thumbnailContainer}>
      {defer ? (
        <Image
          className={thumbnail}
          imgStyle={{
            objectFit: 'contain',
            mixBlendMode: 'multiply',
          }}
          alt={`Product photo for SKU ${get(props, 'sku')}`}
          fluid={{
            src,
          }}
        />
      ) : (
        <Box className={thumbnail}>
          <img
            alt={get(props, 'sku')}
            src={src}
            style={{
              height: '100%',
              mixBlendMode: 'multiply',
              objectFit: 'contain',
              objectPosition: 'center',
              width: '100%',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

ProductPhoto.propTypes = {
  props: PropTypes.shape({
    sku: PropTypes.string,
    photo: PropTypes.string,
    vendor: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
};

export default ProductPhoto;
