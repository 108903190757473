import React from 'react';
import PropTypes from 'prop-types';
import { red } from '@material-ui/core/colors';
import { Link } from 'gatsby';

const withLink =
  (Component) =>
  ({ url, currentUrl, text }) =>
    url === currentUrl ? (
      <Component text={text} />
    ) : (
      <Link
        to={url}
        state={{ fromOffer: true }}
        aria-label="go to product offer"
      >
        <Component text={text} />
      </Link>
    );

const RebateLink = withLink(({ text }) => (
  <em
    style={{
      display: 'block',
      fontSize: '0.79rem',
      lineHeight: 1.1,
      color: '#DE695A',
      margin: '0.15rem 0 0.5rem',
    }}
  >
    {text}
  </em>
));

RebateLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default RebateLink;
