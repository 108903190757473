import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { AddressLine } from 'q3-ui/src/address';
import PropTypes from 'prop-types';
import { toString } from './helpers';

const ProductStock = ({ sku, message, hasAvailable }) => {
  const { t } = useTranslation('labels');

  if (!hasAvailable && !message) return null;

  return typeof sku === 'string' &&
    sku.toLowerCase().startsWith('zz') ? null : (
    <AddressLine
      icon={StorefrontIcon}
      label={t('stockOnHand')}
    >
      {hasAvailable ? toString(hasAvailable) : message}
    </AddressLine>
  );
};
ProductStock.propTypes = {
  sku: PropTypes.string.isRequired,
  message: PropTypes.string,
  hasAvailable: PropTypes.number,
};

ProductStock.defaultProps = {
  message: '',
  hasAvailable: 0,
};

export default ProductStock;
