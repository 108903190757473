import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import YouTube from '@material-ui/icons/YouTube';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Announcement from '@material-ui/icons/Announcement';
import BookIcon from '@material-ui/icons/Book';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import NoteIcon from '@material-ui/icons/Note';
import BuildIcon from '@material-ui/icons/Build';
import { array } from '@lolly-labs/q3-ui-helpers';

const blog = { Component: <BookIcon />, list: ['blog'] };
const news = {
  Component: <Announcement />,
  list: [
    'news',
    'press releases',
    'global/topics',
    'press',
  ],
};
const caseStudies = {
  Component: <AssignmentTurnedInIcon />,
  list: [
    'case studies',
    'resources',
    'academy',
    'case',
    'cases',
    'case-studies',
    'customers',
    'success studies',
    'broken',
  ],
};

const support = {
  Component: <BuildIcon />,
  list: ['support', 'contact'],
};

const xs = [blog, news, caseStudies, support];

export const getIcon = (url) =>
  xs.reduce((acc, x) => {
    return x.list.some((y) => url.includes(y))
      ? acc.concat(x.Component)
      : acc;
  }, [])[0] || <NoteIcon />;

const NetworkIcon = ({ url }) => {
  if (url.includes('facebook')) return <Facebook />;
  if (url.includes('twitter')) return <Twitter />;
  if (url.includes('linkedin')) return <LinkedIn />;
  if (url.includes('youtube')) return <YouTube />;
  if (url.includes('facebook')) return <Facebook />;
  return getIcon(url.toLowerCase());
};

NetworkIcon.propTypes = {
  url: PropTypes.string.isRequired,
};

const Social = ({ links }) => {
  return (
    array.hasLength(links) &&
    links.map((link) => (
      <Fab
        component="a"
        href={link}
        target="__blank"
        rel="noreferrer noopener"
        style={{ margin: '0.25rem' }}
        color="secondary"
      >
        <NetworkIcon url={link} />
      </Fab>
    ))
  );
};

export default Social;
