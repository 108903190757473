import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CardActionArea from '@material-ui/core/CardActionArea';
import Fade from '@material-ui/core/Fade';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import useRest from 'q3-ui-rest';
import { map, size } from 'lodash';
// eslint-disable-next-line
import {
  CarouselProvider,
  Slider,
  Slide,
} from 'pure-react-carousel';
import ProductPhoto from '../components/ProductPhoto';
import ProductCard from '../components/productCard';
import { PromotionSliderPagination } from '../components/PromotionSliderSecondGroup';

const useMediaQueryDown = (dim) =>
  useMediaQuery((theme) => theme.breakpoints.down(dim));

// eslint-disable-next-line
export const ProductsRecommendedSlider = ({ products }) => {
  let visible = 4;
  if (useMediaQueryDown('md')) visible = 2;
  if (useMediaQueryDown('sm')) visible = 1;

  return size(products) ? (
    <Fade in>
      <Container maxWidth="xl">
        <Box position="relative">
          <CarouselProvider
            isIntrinsicHeight
            dragEnabled
            infinite
            touchEnabled
            // eslint-disable-next-line
            totalSlides={products.length}
            visibleSlides={visible}
          >
            <Slider num>
              {map(products, (product, idx) => (
                <Slide index={idx}>
                  <ProductCard {...product} />
                </Slide>
              ))}
            </Slider>
            <PromotionSliderPagination />
          </CarouselProvider>
        </Box>
      </Container>
    </Fade>
  ) : null;
};

const ProductsRecommended = ({ query }) => {
  const {
    fetching,
    fetchingError,
    products = [],
  } = useRest({
    runOnInit: true,
    url: `${COLLECTIONS.PRODUCTS.ENDPOINT}?${query}&limit=16&sort=recommended,priority`,
    key: COLLECTIONS.PRODUCTS.RESOURCE_NAME_SINGULAR,
    pluralized: COLLECTIONS.PRODUCTS.RESOURCE_NAME,
  });

  return fetching || fetchingError ? null : (
    <ProductsRecommendedSlider products={products} />
  );
};

ProductsRecommended.propTypes = {
  query: PropTypes.string.isRequired,
};

export default ProductsRecommended;
