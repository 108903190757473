import { useStaticQuery, graphql } from 'gatsby';
import { get, find } from 'lodash';

const useVendorLogo = (code) => {
  const data = useStaticQuery(graphql`
    {
      allRestVendor {
        nodes {
          code
          logo
        }
      }
    }
  `);

  return get(
    find(
      get(data, 'allRestVendor.nodes'),
      (node) => String(node.code) === String(code),
    ),
    'logo',
    null,
  );
};

export default useVendorLogo;
