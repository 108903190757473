import React from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'q3-ui-locale';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { AddressLine } from 'q3-ui/src/address';

import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { string } from '@lolly-labs/q3-ui-helpers';
import { useAuth } from 'q3-ui-permissions';
import RebateLink from './RebateLink';

const ListItemColumn = ({ label, value, isLast }) => (
  <Box
    component="li"
    borderBottom={!isLast ? '1px solid #DDD' : undefined}
    p={0.05}
  >
    <Grid container spacing={1} alignItems="center">
      <Grid item sm={5} xs={12}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item sm={7} xs={12}>
        <Box align="right">{value}</Box>
      </Grid>
    </Grid>
  </Box>
);

ListItemColumn.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
};

ListItemColumn.defaultProps = {
  isLast: false,
};

const getIcon = (name) => {
  if (name === 'price') return LoyaltyIcon;
  if (name === 'msrp') return LocalOfferIcon;
  if (name === 'map') return DonutLargeIcon;
  if (name === 'volume') return EqualizerIcon;
  if (name === 'originalResellerPrice')
    return SettingsBackupRestoreIcon;
  return () => null;
};

const Pricing = ({
  custom,
  msrp,
  map,
  volume,
  original,
  discounts,
  url,
}) => {
  const { t } = useTranslation('labels');
  const { pathname } = useLocation();
  const state = useAuth();

  const getKeyAsAdmin = (key) =>
    String(state?.state?.profile?.role).startsWith(
      'Back Office',
    )
      ? {
          price: 'Custom Price',
          originalResellerPrice: 'Regular Reseller Price',
        }[key] || key
      : key;

  const listItems = Object.entries({
    price: custom,
    originalResellerPrice: original,
    volume,
    msrp,
    map,
  }).reduce((acc, [key, value]) => {
    if (value)
      acc.push({
        label: t(getKeyAsAdmin(key)),
        icon: getIcon(key),
        value,
      });

    return acc;
  }, []);

  if (!listItems.length) return null;

  return (
    <Box width="375px" maxWidth="100%">
      <Box
        m={0}
        p={0}
        component="ul"
        style={{ listStyle: 'none' }}
        width="100%"
      >
        {listItems.map((item, i) => (
          <Typography
            component="li"
            key={item.label}
            style={{ fontSize: '1rem', margin: 0 }}
          >
            <AddressLine
              icon={LocalOfferIcon}
              isLast={i === listItems.length - 1}
              key={item.label}
              {...item}
            >
              {Array.isArray(discounts) &&
              discounts.length > 0 &&
              i === 0 ? (
                <>
                  <strike
                    style={{
                      color: '#DE695A',
                      marginRight: '0.5rem',
                    }}
                  >
                    <small>
                      {string.toPrice(item.value)}
                    </small>
                  </strike>
                  {string.toPrice(
                    item.value - discounts[0].value,
                  )}
                  <RebateLink
                    url={url}
                    currentUrl={pathname}
                    text={t('rebatesAvailable')}
                  />
                </>
              ) : (
                string.toPrice(item.value)
              )}
            </AddressLine>
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

Pricing.defaultProps = {
  url: '',
};

Pricing.propTypes = {
  custom: PropTypes.number.isRequired,
  map: PropTypes.number.isRequired,
  msrp: PropTypes.number.isRequired,
  url: PropTypes.string,
};

export default Pricing;
