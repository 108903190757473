import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { compact, get } from 'lodash';
import Image from 'gatsby-image';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'q3-ui-locale';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { RichText } from 'gatsby-theme-q3/src/components';
import slugify from 'slugify';
import SEOHelmet from '../components/SEOHelmet';
import Social from '../components/Social';
import useVendorLogo from '../useVendorLogo';
import ProductsRecommended from '../containers/ProductsRecommended';

const VendorDetail = ({
  data: {
    contentfulVendor: {
      name,
      description,
      heroImage,
      socialLinks,
      code,
      title,
      seo,
    },
  },
}) => {
  const { t } = useTranslation();
  const logo = useVendorLogo(code);

  const nameSlugify = slugify(name, {
    lower: true,
    strict: true,
  });

  return (
    <Box bgcolor="background.paper">
      <SEOHelmet
        title={compact([
          get(seo, 'title', name),
          'Vendor',
        ]).join(' | ')}
        description={seo?.metaDescription?.metaDescription}
      />
      <Box p={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} color="inherit" to="/">
            Home
          </MuiLink>
          <MuiLink
            component={Link}
            color="inherit"
            to="/vendors"
          >
            Vendors
          </MuiLink>
          <Typography color="textPrimary">
            {name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        bgcolor="background.default"
        component="header"
        height={450}
        position="relative"
      >
        <Image
          {...heroImage}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'bottom right',
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: '0 auto',
            display: 'block',
          }}
        />
        <Container maxWidth="lg">
          <Box
            bgcolor="background.paper"
            bottom={-1}
            display="flex"
            flexDirection="column-reverse"
            height={150}
            position="absolute"
            style={{
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8,
            }}
            width={230}
          >
            <img
              alt={name}
              src={logo}
              style={{
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
                padding: '1rem',
                width: '100%',
              }}
            />
          </Box>
        </Container>
      </Box>
      <Box py={4}>
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item lg={5} md={6} xs={12}>
              <Typography
                color="secondary"
                variant="overline"
                component="h1"
              >
                {name}
              </Typography>
              <Typography variant="h1" component="h2">
                {title}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="body2" gutterBottom>
                <RichText
                  json={JSON.parse(
                    get(description, 'raw', null),
                  )}
                />
              </Typography>
              <Box my={2}>
                <Button
                  size="large"
                  component={Link}
                  variant="outlined"
                  color="secondary"
                  to={`/product-vendors/${nameSlugify}`}
                >
                  {t('labels:allProducts')}
                </Button>
                <Box display="inline-block" ml={1}>
                  <Button
                    size="large"
                    component={Link}
                    to="/contact"
                    variant="outlined"
                    color="secondary"
                  >
                    {t('labels:getInTouch')}
                  </Button>
                </Box>
              </Box>
              <Social links={socialLinks} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pb={4}>
        <ProductsRecommended
          query={`vendor.code=${code}`}
        />
      </Box>
    </Box>
  );
};

VendorDetail.propTypes = {
  data: PropTypes.shape({
    vendor: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      social: PropTypes.arrayOf(PropTypes.string),
    }),
    // eslint-disable-next-line
    contentfulVendor: PropTypes.object,
  }).isRequired,
};

export default VendorDetail;

export const query = graphql`
  query getVendorById($contentful_id: String) {
    contentfulVendor(
      contentful_id: { eq: $contentful_id }
    ) {
      name
      seo {
        title
        metaDescription {
          metaDescription
        }
      }
      heroImage {
        alt: title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      description {
        raw
      }
      code
      socialLinks
      title
    }
  }
`;
