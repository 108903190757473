import React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Button,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import { isString } from 'lodash';

const useStyle = makeStyles((theme) => ({
  bg: ({
    artDirection = 'center',
    imageBackgroundBehaviour = 'cover',
    bgColor,
    backgroundColourHex,
  }) => {
    const getBackgroundColor = () => {
      if (isString(backgroundColourHex)) {
        return backgroundColourHex.startsWith('#')
          ? backgroundColourHex
          : `#${backgroundColourHex}`;
      }

      if (bgColor === 'primary')
        return theme.palette.primary.main;
      if (bgColor === 'secondary')
        return theme.palette.secondary.main;

      return '#f2f2f2';
    };

    return {
      backgroundColor: getBackgroundColor(),
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,

      '& .gatsby-image-wrapper': {
        height: '100%',
        width: '100%',
        backgroundColor: 'inherit',
      },

      '& img': {
        position: 'relative',
        objectFit: `${imageBackgroundBehaviour} !important`,
        objectPosition: `${artDirection} !important`,
        height: '100%',
        width: '100%',
      },
    };
  },
  content: {
    maxWidth: '74%',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      maxWidth: '82% !important',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100% !important',
    },
  },
  gap: {
    paddingTop: theme.spacing(2),
  },
  root: ({ image }) => ({
    height: '100%',
    padding: theme.spacing(4),
    position: 'relative',
    minHeight: 650,

    [theme.breakpoints.down('md')]: {
      minHeight: image
        ? '450px !important'
        : 'auto !important',
    },
  }),
  label: () => ({
    backgroundColor: '#ECAE4B',
    color: theme.palette.secondary.contrastText,
    position: 'relative',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    display: 'inline-flex',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'small',
    alignItems: 'center',

    '&::before': {
      content: '""',
      backgroundColor: '#ECAE4B',
      position: 'absolute',
      left: `-${theme.spacing(4)}`,
      top: 0,
      bottom: 0,
      width: theme.spacing(6),
    },

    '& span': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      fontSize: '1em',
    },
  }),
  title: ({ color, resizeTitle, textSize = 'Regular' }) => {
    const getColour = () => {
      if (color === 'contrast')
        return theme.palette.primary.contrastText;

      if (color === 'secondary')
        return theme.palette.secondary.main;

      return theme.palette.primary.light;
    };

    const fontSizes = ['3.453rem', '3.047rem', '2.027rem'];

    const fontSizeIndex = textSize
      ? ['Large', 'Regular', 'Small'].findIndex(
          (item) => item === textSize,
        )
      : 1;

    return {
      color: getColour(),
      fontSize: resizeTitle
        ? fontSizes[fontSizeIndex]
        : undefined,
      lineHeight: `${1.1} !important`,

      '& p': {
        fontFamily: `${theme.typography.h1.fontFamily} !important`,
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        margin: '0 !important',
        textShadow: `0 0 0 ${getColour()}`,
        fontWeight: 600,
      },

      [theme.breakpoints.down('lg')]: {
        fontSize: resizeTitle
          ? `${
              fontSizes[Math.min(2, fontSizeIndex + 1)]
            } !important`
          : undefined,
      },

      [theme.breakpoints.down('md')]: {
        fontSize: resizeTitle
          ? `${theme.typography.h1.fontSize} !important`
          : `${theme.typography.h2.fontSize} !important`,
      },
    };
  },
  description: ({ textSize, color }) => ({
    maxWidth: textSize === 'Large' ? '22vw' : '100%',
    color:
      color === 'contrast'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.light,

    '& .rte *': {
      color: 'inherit',
      fontSize: theme.typography.body2.fontSize,
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',

      '& p': {
        fontSize: '1rem',
      },
    },
  }),
  overlay: ({ color }) => ({
    background:
      color === 'contrast'
        ? 'rgba(2,2,2,0.9)'
        : 'rgba(255,255,255,0.9)',
    filter: 'blur(66px)',
    position: 'absolute',
    borderRadius: 53,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  }),
}));

const PromotionSliderBlockLarge = ({
  data,
  height,
  maxWidth,
  minHeight,
  titleTypographyProps,
}) => {
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const cls = useStyle({
    resizeTitle: !titleTypographyProps,
    ...data,
  });

  const getButtonProps = () => {
    const { color: textColor } = data;
    let color = textColor;
    let variant = 'outlined';

    if (textColor === 'contrast') {
      color = 'inherit';
    } else if (textColor === 'secondary') {
      variant = 'contained';
    }

    return {
      color,
      variant,
      component: Link,
      // eslint-disable-next-line
      to: data.to || data.buttonUrl || '/',
      size: isMobile ? undefined : 'large',
    };
  };

  return data?.nameRt?.raw ? (
    <Box
      className={cls.root}
      style={{
        height,
        minHeight,
        position: 'relative',
      }}
    >
      <Box className={cls.bg}>
        {data?.image && <Image {...data?.image} />}
      </Box>
      <Box
        className={cls.content}
        style={{
          maxWidth,
        }}
      >
        {data?.image && <div className={cls.overlay} />}
        <div style={{ position: 'relative' }}>
          {data.label && (
            <Box className={cls.label} component="span">
              <span>{data.label}</span>
            </Box>
          )}

          <Typography
            className={cls.title}
            variant="h1"
            {...titleTypographyProps}
            component="div"
          >
            <RichText json={JSON.parse(data.nameRt.raw)} />
          </Typography>
          <Box className={cls.description}>
            {data.descriptionRt?.raw && (
              <Box mt={1} className="rte">
                <RichText
                  json={JSON.parse(data.descriptionRt.raw)}
                />
              </Box>
            )}
            <Box className={cls.gap}>
              <Button {...getButtonProps()}>
                {data.button || 'Learn more'}
              </Button>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  ) : null;
};

PromotionSliderBlockLarge.defaultProps = {
  data: {},
};

PromotionSliderBlockLarge.propTypes = {
  data: PropTypes.shape({
    artDirection: PropTypes.string,
    color: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    title: PropTypes.string,
    bgColor: PropTypes.string,
  }),
};

export default PromotionSliderBlockLarge;
