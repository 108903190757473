import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Container,
} from '@material-ui/core';
// eslint-disable-next-line
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import { size } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PromotionSliderBlockLarge from './PromotionSliderBlockLarge';
// eslint-disable-next-line
import 'pure-react-carousel/dist/react-carousel.es.css';

const useStyle = makeStyles((theme) => {
  const css = {
    background: 'transparent',
    border: 0,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h2.fontSize,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',

    '&[disabled]': {
      display: 'none',
    },
  };

  return {
    back: {
      ...css,
      left: theme.spacing(1),
    },
    next: {
      ...css,
      right: theme.spacing(1),
    },
  };
});

export const PromotionSliderPagination = () => {
  const cls = useStyle();

  return (
    <>
      <ButtonBack className={cls.back} aria-label="before">
        <NavigateBeforeIcon />
      </ButtonBack>
      <ButtonNext className={cls.next} aria-label="next">
        <NavigateNextIcon />
      </ButtonNext>
    </>
  );
};

const PromotionSliderSecondGroup = ({ data }) => {
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );

  const visible = isMobile ? 1 : 2;

  return size(data) ? (
    <Box component="section" position="relative">
      <Container maxWidth="xl" disableGutters>
        <CarouselProvider
          isIntrinsicHeight
          totalSlides={data.length}
          dragEnabled={visible / data.length > 1}
          visibleSlides={visible}
        >
          <Slider>
            {data.map((slide, idx) => (
              <Slide index={idx} key={idx}>
                <PromotionSliderBlockLarge data={slide} />
              </Slide>
            ))}
          </Slider>
          {data.length > 1 && <PromotionSliderPagination />}
        </CarouselProvider>
      </Container>
    </Box>
  ) : null;
};

PromotionSliderSecondGroup.defaultProps = {
  data: [],
};

PromotionSliderSecondGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      variant: PropTypes.string,
    }),
  ),
};

export default PromotionSliderSecondGroup;
