import React from 'react';
import { get, size } from 'lodash';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import ProductStock from 'gatsby-theme-gentek/src/components/ProductStock';
import ProductPricing from 'gatsby-theme-gentek/src/components/ProductPricing';
import { AddToCart } from 'q3-ui-cart';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useResellerLogin from '../useResellerLogin';
import ProductPhoto from './ProductPhoto';

export const CardColumn = ({ children }) => (
  <Grid item lg={3} md={6} xs={12}>
    {children}
  </Grid>
);

export const CardWrapper = ({ children }) => (
  <Fade in>
    <Box height="100%">{children}</Box>
  </Fade>
);

const ProductAddToCart = ({
  id,
  isPurchasable,
  custom,
  companions,
  url,
}) => {
  const { isLoggedIn, ordersEnabled } = useResellerLogin();

  return isLoggedIn &&
    isPurchasable &&
    custom &&
    ordersEnabled ? (
    <Box mt={1.5} width="100%">
      {size(companions) ? (
        <Button
          size="large"
          color="primary"
          fullWidth
          variant="contained"
          component={Link}
          to={url}
        >
          Buy now <ChevronRightIcon />
        </Button>
      ) : (
        <AddToCart
          product={id}
          size="small"
          variant="spread"
        />
      )}
    </Box>
  ) : null;
};

ProductAddToCart.propTypes = {
  id: PropTypes.string.isRequired,
  isPurchasable: PropTypes.bool.isRequired,
};

export const makeProductTitle = ({
  manufacturerSku,
  mod,
  vendor,
  sku,
}) =>
  [
    vendor?.name,
    sku,
    // mod || manufacturerSku
  ]
    .filter(Boolean)
    .join(' ');

export const makeSkuBiline = ({ sku, manufacturerSku }) =>
  // ` Gentek ${sku} · MFG ${manufacturerSku}`;
  `MFG ${manufacturerSku}`;

const ProductCardHeader = ({ url, ...rest }) => (
  <CardHeader
    title={makeProductTitle(rest)}
    subheader={
      <Typography component="strong" color="secondary">
        <small>{makeSkuBiline(rest)}</small>
      </Typography>
    }
    style={{
      color: 'inherit',
      padding: 0,
      textDecoration: 'none',
      marginTop: '1rem',
      marginBottom: '.5rem',
    }}
    component={Link}
    to={url}
    titleTypographyProps={{
      component: 'h2',
      color: 'primary',
      style: {
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
        fontSize: '1.5909rem',
      },
    }}
  />
);

ProductCardHeader.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ({
  id,
  sku,
  manufacturerSku,
  excerpt,
  vendor,
  category,
  fullWidth,
  halfWidth,
  price,
  stock,
  type,
  discounts,
  photo,
  inventory,
  companions,
  ...rest
}) => {
  const url = `/products/${sku.toLowerCase()}`;

  return sku ? (
    <CardWrapper
      fullWidth={fullWidth}
      halfWidth={halfWidth}
    >
      <Card
        component="article"
        elevation={0}
        style={{
          height: '100%',
          justifyContent: 'space-between',
          flexDirection: 'column',
          display: 'flex',
          padding: '1rem',
        }}
      >
        <Box width="100%">
          <Box bgcolor="background.muted">
            <CardActionArea onClick={() => navigate(url)}>
              <ProductPhoto
                sku={sku}
                vendor={vendor}
                photo={photo}
                backgroundColor="transparent"
                // size="small"
                {...rest}
              />
            </CardActionArea>
          </Box>
          <ProductCardHeader
            id={id}
            sku={sku}
            manufacturerSku={manufacturerSku}
            url={url}
            vendor={vendor}
            {...rest}
          />
          <CardContent style={{ padding: 0 }}>
            {excerpt && (
              <Typography
                variant="body1"
                style={{ fontSize: '0.91rem' }}
              >
                {excerpt}
              </Typography>
            )}
            {!get(stock, 'discontinued') && (
              <ProductPricing
                url={url}
                {...price}
                discounts={discounts}
              />
            )}

            <ProductStock {...stock} {...inventory} />
          </CardContent>
        </Box>

        <ProductAddToCart
          id={id}
          url={url}
          companions={companions}
          {...stock}
          {...price}
        />
      </Card>
    </CardWrapper>
  ) : null;
};
